import { ref, shallowRef } from 'vue'
import { defineStore } from 'pinia'

import { APP } from '@/common/constants'
import { getColors } from '@/utils/themeHelpers'

const useTheme = defineStore('theme', () => {
  const currentTheme = ref(null)
  const colors = shallowRef({})
  // FIXME: temporary flag until themes won't be released
  const switchAvailable = process.env.DEFAULT_THEME === APP.THEMES.DARK

  function init() {
    switchTheme(process.env.DEFAULT_THEME || APP.THEMES.LIGHT)
  }

  function destroy() {
    currentTheme.value = null
    colors.value = {}
  }

  function toggleTheme() {
    if (currentTheme.value === APP.THEMES.DARK) {
      switchTheme(APP.THEMES.LIGHT)
    } else {
      switchTheme(APP.THEMES.DARK)
    }
  }

  function switchTheme(theme) {
    if (Object.values(APP.THEMES).includes(theme)) {
      document.documentElement.classList.remove(currentTheme.value)
      document.documentElement.classList.add(theme)
      currentTheme.value = theme
      colors.value = getColors()
    }
  }

  return {
    switchAvailable,
    currentTheme,
    colors,
    init,
    destroy,
    toggleTheme
  }
})

export default useTheme
